import React from "react";
import "./NavigationMenuPrestation.css";
import MainIcon from "../../assets/landing/whatsapp.svg";
import Icon1 from "../../assets/landing/call.png";
import Icon2 from "../../assets/landing/mail.png";

import arrowIcon from "../../assets/landing/arrow.png";

function NavigationMenuPrestation() {
  const scrollToDiv = () => {
    const scrollId = document.getElementById("prestations");
    scrollId.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div className="nav-container2">
      <div className="fab2">
        <img
          onClick={scrollToDiv}
          style={{ width: "48px", height: "48px" }}
          className={"icon"}
          src={arrowIcon}
        />

        <div className="fab_actions2">
          <img className={"icon"} src={MainIcon} />

          <img className={"icon"} src={Icon1} />

          <img className={"icon"} src={Icon2} />
        </div>
      </div>
    </div>
  );
}

export default NavigationMenuPrestation;
