import React, { useEffect, useRef } from "react";
import logo from "../assets/landing/logo.png";
import { Box, Typography } from "@mui/material";
import NavigationMenu from "./commons/NavigationMenu";
import NavigationMenuPrestation from "./commons/NavigationMenuPrestation";

const Landing = () => {
  const canvasRef = useRef(null);
  const ctxRef = useRef(null);
  const mouseMovedRef = useRef(false);

  const mouse = {
    x: 0.5 * window.innerWidth,
    y: 0.5 * window.innerHeight,
    tX: 0,
    tY: 0,
  };

  const params = {
    pointsNumber: 40,
    widthFactor: 0.3,
    mouseThreshold: 0.6,
    spring: 0.4,
    friction: 0.5,
  };

  const touchTrail = new Array(params.pointsNumber).fill().map(() => ({
    x: mouse.x,
    y: mouse.y,
    vx: 0,
    vy: 0,
  }));

  const updateMousePosition = (eX, eY) => {
    mouse.tX = eX;
    mouse.tY = eY;
  };

  const setupCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const containerHeight = document.getElementById("my-container");

    canvas.width = window.innerWidth;
    canvas.height = containerHeight.clientHeight;
    ctx.fillStyle = "#202020";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctxRef.current = ctx;
  };

  const updateBubbles = (t) => {
    const ctx = ctxRef.current;
    if (!mouseMovedRef.current) {
      mouse.tX =
        (0.5 + 0.3 * Math.cos(0.002 * t) * Math.sin(0.005 * t)) *
        window.innerWidth;
      mouse.tY =
        (0.5 + 0.2 * Math.cos(0.005 * t) + 0.1 * Math.cos(0.01 * t)) *
        window.innerHeight;
    }

    if (canvasRef.current.width) {
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      ctx.beginPath();
    }

    touchTrail.forEach((p, pIdx) => {
      if (pIdx === 0) {
        p.x = mouse.x;
        p.y = mouse.y;
        ctx.moveTo(p.x, p.y);
      } else {
        p.vx += (touchTrail[pIdx - 1].x - p.x) * params.spring;
        p.vy += (touchTrail[pIdx - 1].y - p.y) * params.spring;
        p.vx *= params.friction;
        p.vy *= params.friction;

        p.x += p.vx;
        p.y += p.vy;
      }
    });

    for (let i = 1; i < touchTrail.length - 1; i++) {
      const xc = 0.5 * (touchTrail[i].x + touchTrail[i + 1].x);
      const yc = 0.5 * (touchTrail[i].y + touchTrail[i + 1].y);
      ctx.quadraticCurveTo(touchTrail[i].x, touchTrail[i].y, xc, yc);
      ctx.lineWidth = params.widthFactor * (params.pointsNumber - i);
      ctx.strokeStyle = "#C6AB7C";
      ctx.stroke();
    }
    ctx.lineTo(
      touchTrail[touchTrail.length - 1].x,
      touchTrail[touchTrail.length - 1].y,
    );
    ctx.strokeStyle = "#C6AB7C";

    ctx.stroke();

    mouse.x += (mouse.tX - mouse.x) * params.mouseThreshold;
    mouse.y += (mouse.tY - mouse.y) * params.mouseThreshold;

    window.requestAnimationFrame(updateBubbles);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.addEventListener("click", (e) => {
      updateMousePosition(e.pageX, e.pageY);
    });
    canvas.addEventListener("mousemove", (e) => {
      mouseMovedRef.current = true;
      updateMousePosition(e.pageX, e.pageY);
    });
    canvas.addEventListener("touchmove", (e) => {
      mouseMovedRef.current = true;
      updateMousePosition(e.targetTouches[0].pageX, e.targetTouches[0].pageY);
    });

    setupCanvas();
    updateBubbles(0);

    window.addEventListener("resize", setupCanvas);

    return () => {
      window.removeEventListener("resize", setupCanvas);
    };
  }, []);

  return (
    <div>
      <Box
        id={"my-container"}
        sx={{
          minHeight: {
            xs: "300px",
            sm: "400px",
            md: "400px",
            lg: "400px",
            xl: "700px",
          },
        }}
        style={{
          backgroundColor: "#202020",
          position: "relative",
        }}
      >
        <Box
          component="img"
          sx={{
            width: { xs: "90%", sm: "80%", md: "50%", lg: "50%", xl: "50%" },
            top: { xs: "50%", sm: "40%", md: "30%", lg: "40%", xl: "30%" },
          }}
          src={logo}
          alt="Logo"
          style={{
            position: "absolute",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
          }}
        ></Box>

        <canvas
          ref={canvasRef}
          style={{ position: "absolute", top: 0, left: 0, zIndex: 2 }}
        ></canvas>
      </Box>
      <Box
        sx={{
          minHeight: {
            xs: "300px",
            sm: "250px",
          },
        }}
        style={{
          backgroundColor: "#202020",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <div style={{ position: "relative" }}>
            <Typography
              component={"div"}
              sx={{
                fontFamily: "Josefin Bold",
                fontSize: {
                  xs: "30px",
                  sm: "50px",
                },
              }}
              color={"#EA4031"}
              textAlign={"center"}
            >
              Une urgence?{" "}
              <div style={{ fontSize: "30px" }}>Disponible 24/7 !</div>
            </Typography>

            <NavigationMenu />
          </div>
          <div style={{ position: "relative" }}>
            <Typography
              component={"div"}
              sx={{
                fontFamily: "Josefin Bold",
                fontSize: {
                  xs: "30px",
                  sm: "50px",
                },
              }}
              color={"#C6AB7C"}
              textAlign={"center"}
            >
              Rénovations
              <div style={{ fontSize: "30px" }}>Voir nos prestations</div>
            </Typography>

            <NavigationMenuPrestation />
          </div>
        </div>
      </Box>
    </div>
  );
};

export default Landing;
