import "./App.css";
import Landing from "./components/Landing";
import Section1 from "./components/Section1";

import "./fonts.css";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Footer from "./components/Footer";
import Section4 from "./components/Section4";
function App() {
  return (
    <div style={{ backgroundColor: "#FBF9F7", width: "100%" }}>
      <Landing> </Landing>

      <Section1></Section1>
      <Section4></Section4>

      <Section2></Section2>
      <Section3></Section3>
      <Footer></Footer>
    </div>
  );
}

export default App;
