import React from "react";
import "./ContactForm.css";
import { useForm } from "@formspree/react";
import PinkButtonComponent from "../PinkButton";

function ContactForm() {
  const [state, handleSubmit] = useForm("mjvqjrdg");

  if (state.succeeded) {
    return (
      <div className="container">
        <div style={{ color: "white" }}>
          Votre formulaire a bien été envoyé ! On s'occupe du reste !
        </div>
      </div>
    );
  }
  return (
    <div className="container">
      <form onSubmit={handleSubmit} style={{ position: "relative" }}>
        <input type="hidden" name="_language" value="fr" />
        <div className="form-row">
          <div className="input-data">
            <input id="Nom" name="Nom" type="text" required />
            <label htmlFor="Nom">Votre nom</label>
          </div>
          <div className="input-data">
            <input id="Prénom" name="Prénom" type="text" required />
            <label htmlFor="Prénom">Votre prénom</label>
          </div>
        </div>
        <div className="form-row">
          <div className="input-data">
            <input
              id="Adresse E-mail"
              name="Adresse E-mail"
              type="email"
              required
            />
            <label htmlFor="Adresse E-mail">Votre adresse e-mail</label>
          </div>
          <div className="input-data">
            <input
              id="Numéro de téléphone"
              name="Numéro de téléphone"
              type="number"
              required
            />
            <label htmlFor="Numéro de téléphone">Votre n° de téléphone</label>
          </div>
        </div>
        <div className="form-row">
          <div className="input-data textarea">
            <textarea
              id="Message"
              name="Message"
              rows="8"
              cols="80"
              required
            ></textarea>
            <br />
            <label htmlFor="Message">
              Donnez-nous un maximum d'informations sur votre projet
            </label>
            <br />
          </div>
        </div>

        <PinkButtonComponent
          title={"Envoyer le formulaire"}
          type="submit"
          disabled={state.submitting}
          sxProps={{
            left: "50%",
            position: "absolute",
            transform: "translate(-50%, -50%)",
            marginTop: "30px",
          }}
        >
          Envoyer le formulaire
        </PinkButtonComponent>
      </form>
    </div>
  );
}

export default ContactForm;
