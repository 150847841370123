import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import fuite from "../assets/section1/fuite.png";
import panne from "../assets/section1/panne.png";
import chauffage from "../assets/section1/chauffage.png";
import PinkButtonComponent from "./PinkButton";

const StyledYellowBox = styled(Box)({
  backgroundColor: "#202020",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  height: "100%",
  maxWidth: "450px",
});

const JosefinBlackTypo = styled(Typography)({
  fontFamily: "Josefin Regular",
  color: "#CEC18C",
});

const CenteredContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
});

const PinkButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
});

const Section1 = () => {
  const phoneNumber = "352661741571"; // Replace with the desired phone number

  const handlePhoneButtonClick = () => {
    console.log("ehe oohh");
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <div style={{ padding: "16px" }}>
      <div
        style={{
          fontFamily: "Josefin Bold",
          fontSize: "50px",
          textAlign: "center",
          marginTop: "50px",
          color: "#202020",
        }}
      >
        Services de plomberie, électricité et chauffage 24/7
      </div>
      <Grid
        container
        spacing={2}
        display={"flex"}
        justifyContent={"center"}
        marginTop={4}
      >
        {/* Premier bloc */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{ margin: { xs: "16px", sm: "40px 0" } }}
          display={"flex"}
          justifyContent={"center"}
        >
          <StyledYellowBox>
            <CenteredContent>
              <Typography
                component={"div"}
                fontFamily="Josefin Regular"
                fontSize="25px"
                color="#CEC18C"
                textAlign="center"
              >
                Besoin d'un{" "}
                <mark style={{ backgroundColor: "#5AABE3", color: "white" }}>
                  plombier
                </mark>{" "}
                en urgence?
              </Typography>
              <img
                alt={"fuite"}
                src={fuite}
                width="200px"
                height="200px"
                style={{ textAlign: "center" }}
              />
            </CenteredContent>
            <Box>
              <JosefinBlackTypo>
                • Remplacement de chauffe-eau ou de robinet nécessaire ?
              </JosefinBlackTypo>
              <JosefinBlackTypo sx={{ marginTop: "10px" }}>
                • Problème de robinet?
              </JosefinBlackTypo>
              <JosefinBlackTypo sx={{ marginTop: "10px" }}>
                • Problème de toilettes ou de canalisation bouchée ?
              </JosefinBlackTypo>
              <JosefinBlackTypo sx={{ marginTop: "10px" }}>
                • Un plombier qualifié chez vous en moins de 30 minutes
              </JosefinBlackTypo>
            </Box>
            <PinkButtonContainer>
              <PinkButtonComponent
                onClick={() => handlePhoneButtonClick()}
                title={"Contacter un plombier"}
              />
            </PinkButtonContainer>
          </StyledYellowBox>
        </Grid>

        {/* Deuxième bloc */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{ margin: { xs: "16px", sm: "40px 0" } }}
          display={"flex"}
          justifyContent={"center"}
        >
          <StyledYellowBox>
            <CenteredContent>
              <Typography
                component={"div"}
                fontFamily="Josefin Regular"
                fontSize="25px"
                color="#CEC18C"
                textAlign="center"
              >
                Besoin d'un{" "}
                <mark style={{ backgroundColor: "#E2BB48", color: "black" }}>
                  électricien
                </mark>{" "}
                en urgence?
              </Typography>
              <img
                src={panne}
                width="200px"
                height="200px"
                style={{ textAlign: "center" }}
                alt={"panne"}
              />
            </CenteredContent>
            <Box>
              <JosefinBlackTypo>
                • Besoin d'installation d'une lampe ? Remplacement de fusibles ?
              </JosefinBlackTypo>
              <JosefinBlackTypo sx={{ marginTop: "10px" }}>
                • Mise aux normes de votre installation électrique ?
              </JosefinBlackTypo>
              <JosefinBlackTypo sx={{ marginTop: "10px" }}>
                • Confronté à un problème plus important ?
              </JosefinBlackTypo>
              <JosefinBlackTypo sx={{ marginTop: "10px" }}>
                • Un électricien qualifié chez vous en moins de 30 minutes
              </JosefinBlackTypo>
            </Box>
            <PinkButtonContainer>
              <PinkButtonComponent
                onClick={() => handlePhoneButtonClick()}
                title={"Un électricien maintenant"}
              />
            </PinkButtonContainer>
          </StyledYellowBox>
        </Grid>

        {/* Troisième bloc */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{ margin: { xs: "16px", sm: "40px 0" } }}
          display={"flex"}
          justifyContent={"center"}
        >
          <StyledYellowBox>
            <CenteredContent>
              <Typography
                component={"div"}
                fontFamily="Josefin Regular"
                fontSize="25px"
                color="#CEC18C"
                textAlign="center"
              >
                Besoin d'un{" "}
                <mark style={{ backgroundColor: "#EA4031", color: "#FCB100" }}>
                  chauffagiste
                </mark>{" "}
                en urgence?
              </Typography>
              <img
                alt={"chauffage"}
                src={chauffage}
                style={{ width: "150px", height: "200px", textAlign: "center" }}
              />
            </CenteredContent>
            <Box>
              <JosefinBlackTypo>• Problème de chauffage ?</JosefinBlackTypo>
              <JosefinBlackTypo sx={{ marginTop: "10px" }}>
                • Faire l'entretien de votre chaudière?
              </JosefinBlackTypo>
              <JosefinBlackTypo sx={{ marginTop: "10px" }}>
                • Ou alors la remplacer complètement?
              </JosefinBlackTypo>
              <JosefinBlackTypo sx={{ marginTop: "10px" }}>
                • Un chauffagiste qualifié chez vous en moins de 30 minutes
              </JosefinBlackTypo>
            </Box>
            <PinkButtonContainer>
              <PinkButtonComponent
                onClick={() => handlePhoneButtonClick()}
                title={"Contacter un chauffagiste"}
              />
            </PinkButtonContainer>
          </StyledYellowBox>
        </Grid>
      </Grid>
    </div>
  );
};

export default Section1;
