import React from "react";
import salleDeBain from "../assets/section4-prestations/salleDeBain.jpg";
import maison from "../assets/section4-prestations/maison.jpg";
import extension from "../assets/section4-prestations/extension.jpg";
import terrassement from "../assets/section4-prestations/terrassement.jpg";
import cuisine from "../assets/section4-prestations/cuisine.jpg";
import { Box, useMediaQuery, useTheme } from "@mui/material";

import BlueButtonComponent from "./BlueButton";

const Section4 = () => {
  const { breakpoints } = useTheme();
  const isSmartphone = useMediaQuery(breakpoints.only("xs"));

  const handleBlueButtonClick = () => {
    const targetElement = document.getElementById("targetDiv");
    targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return (
    <div
      style={{
        backgroundColor: "#FBF9F7",
        padding: "16px",
      }}
    >
      <div
        id={"prestations"}
        style={{
          fontFamily: "Josefin Bold",
          fontSize: "50px",
          textAlign: "center",
          marginTop: "50px",
          marginBottom: "50px",
          color: "#202020",
        }}
      >
        Nos prestations
      </div>

      <Box
        style={{ display: "flex" }}
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "center", sm: "unset" },
          marginTop: "50px",
        }}
      >
        <Box
          component="img"
          sx={{
            width: { xs: "100%", sm: "50%" },
            borderRadius: "20px",
            maxWidth: "500px",
            display: { xs: "none", sm: "block" },
          }}
          src={salleDeBain}
          alt="Logo"
        ></Box>
        <Box
          sx={{
            width: { xs: "unset", sm: "100%" },
            marginLeft: { xs: "0px", sm: "20px" },
            backgroundColor: "#202020",
            padding: "16px",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "30px",
              color: "#B79B5D",
              textAlign: "center",
            }}
          >
            RENOVATION SALLE DE BAIN
          </div>
          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "30px",
              color: "#CEC18C",
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            Experts de la rénovation, nous mettons à profit notre savoir-faire
            pour transformer vos espaces avec excellence.
          </div>

          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "20px",
              color: "#8C8C8C",
              marginTop: "20px",
            }}
          >
            Nos employés hautement qualifiés nous permettent de proposer
            fièrement des solutions novatrices pour les projets de rénovation et
            d'aménagement intérieur à venir.
          </div>
          <Box
            component="img"
            sx={{
              width: { xs: "100%", sm: "50%" },
              borderRadius: "20px",
              maxWidth: "500px",
              height: "300px",
              display: isSmartphone ? "block" : "none",
              marginTop: "16px",
            }}
            src={salleDeBain}
            alt="Logo"
          ></Box>
          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "20px",
              color: "white",
              marginTop: "20px",
            }}
          >
            <ul>
              <li style={{ marginTop: "5px" }}>Pose de sol</li>
              <li style={{ marginTop: "5px" }}>
                Création de plafonds contemporains
              </li>
              <li style={{ marginTop: "5px" }}>Pose de cloisons sèches</li>
              <li style={{ marginTop: "5px" }}>
                Carrelage, peinture, papier peint
              </li>
            </ul>
          </div>

          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "20px",
              color: "#8C8C8C",
              marginTop: "20px",
            }}
          >
            Nous nous spécialisons également dans l'aménagement de salles de
            bains, ainsi que la vente et l'installation de rideaux et de
            marquises.
          </div>
          <BlueButtonComponent
            sxProps={{ marginTop: "20px" }}
            onClick={() => handleBlueButtonClick()}
            title={"Nous parler de votre projet"}
          />
        </Box>
      </Box>

      {/*  //NEW ITEM*/}

      <Box
        style={{ display: "flex" }}
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "center", sm: "unset" },
          marginTop: "50px",
        }}
      >
        <Box
          sx={{
            width: { xs: "unset", sm: "100%" },
            backgroundColor: "#849A8B",
            padding: "16px",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "30px",
              color: "#CCB496",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            Rénovation Intégrale Maisons et Appartements
          </div>
          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "30px",
              color: "#CCB496",
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            Nous offrons un service complet pour améliorer votre intérieur,
            créant ainsi des espaces de vie agréables qui ajoutent de la valeur
            à votre propriété.
          </div>
          <Box
            component="img"
            sx={{
              width: { xs: "100%", sm: "50%" },
              borderRadius: "20px",
              maxWidth: "500px",
              height: "300px",
              display: isSmartphone ? "block" : "none",
              marginTop: "16px",
            }}
            src={maison}
            alt="Logo"
          ></Box>
          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "20px",
              color: "black",
              marginTop: "20px",
            }}
          >
            Envisagez-vous une rénovation complète de votre maison ? Nous vous
            proposons :
          </div>

          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "20px",
              color: "white",
              marginTop: "20px",
            }}
          >
            <ul>
              <li>Installation d'une nouvelle chaudière</li>
              <li style={{ marginTop: "5px" }}>
                Mise en place du chauffage au sol
              </li>
              <li style={{ marginTop: "5px" }}>Création de nouvelles pièces</li>
              <li style={{ marginTop: "5px" }}>
                Installation de fenêtres avec système domotique
              </li>
              <li style={{ marginTop: "5px" }}>
                Conception de plans architecturaux
              </li>
              <li style={{ marginTop: "5px" }}>
                Pose de carrelage et de parquet
              </li>
            </ul>
          </div>
        </Box>
        <Box
          component="img"
          sx={{
            marginLeft: { xs: "0px", sm: "20px" },
            width: { xs: "100%", sm: "50%" },
            borderRadius: "20px",
            maxWidth: "500px",
            display: { xs: "none", sm: "block" },
          }}
          src={maison}
          alt="Logo"
        ></Box>
      </Box>

      {/*  //NEW ITEM*/}
      <Box
        style={{ display: "flex" }}
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "center", sm: "unset" },
          marginTop: "50px",
        }}
      >
        <Box
          component="img"
          sx={{
            width: { xs: "100%", sm: "50%" },
            borderRadius: "20px",
            maxWidth: "500px",
            display: { xs: "none", sm: "block" },
          }}
          src={extension}
          alt="Logo"
        ></Box>

        <Box
          sx={{
            width: { xs: "unset", sm: "100%" },
            marginLeft: { xs: "0px", sm: "20px" },
            backgroundColor: "#202020",
            padding: "16px",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "30px",
              color: "#039BCB",
              textAlign: "center",
            }}
          >
            EXTENSION DE MAISON
          </div>
          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "30px",
              color: "#039BCB",
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            Optimisez votre espace intérieur pour un environnement plus spacieux
          </div>
          <Box
            component="img"
            sx={{
              width: { xs: "100%", sm: "50%" },
              borderRadius: "20px",
              maxWidth: "500px",
              height: "300px",
              display: isSmartphone ? "block" : "none",
              marginTop: "16px",
            }}
            src={extension}
            alt="Logo"
          ></Box>
          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "20px",
              color: "#8C8C8C",
              marginTop: "20px",
            }}
          >
            Envisagez-vous d'agrandir votre maison ?
          </div>

          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "20px",
              color: "white",
              marginTop: "20px",
            }}
          >
            <div style={{ marginTop: "20px" }}>
              Nous élaborons des plans conformes aux règles d'urbanisme,
              permettant d'augmenter l'espace de vie de votre maison en créant
              des mètres carrés supplémentaires qui ajoutent de la valeur à
              votre propriété.
            </div>
            <div style={{ marginTop: "20px", color: "#8C8C8C" }}>
              Une extension bien pensée est essentielle pour obtenir les
              autorisations nécessaires et transformer votre habitat en lui
              donnant une nouvelle dimension.
            </div>
          </div>

          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "20px",
              color: "white",
              marginTop: "20px",
            }}
          >
            Nous nous spécialisons également dans l'aménagement de salles de
            bains, ainsi que la vente et l'installation de rideaux et de
            marquises.
          </div>
          <BlueButtonComponent
            sxProps={{ marginTop: "20px" }}
            onClick={() => handleBlueButtonClick()}
            title={"Nous parler de votre projet"}
          />
        </Box>
      </Box>

      {/*  //NEW ITEM*/}
      <Box
        style={{ display: "flex" }}
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "center", sm: "unset" },
          marginTop: "50px",
        }}
      >
        <Box
          sx={{
            width: { xs: "unset", sm: "100%" },
            backgroundColor: "#202020",
            padding: "16px",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "30px",
              color: "#E3C582",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            Services de Terrassement et d'Égout
          </div>
          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "30px",
              color: "#E3C582",
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            Cultivez la confiance avec notre service de terrassement de qualité,
            façonnant des fondations solides pour vos projets.
          </div>
          <Box
            component="img"
            sx={{
              width: { xs: "100%", sm: "50%" },
              borderRadius: "20px",
              maxWidth: "500px",
              height: "300px",
              display: isSmartphone ? "block" : "none",
              marginTop: "16px",
            }}
            src={terrassement}
            alt="Logo"
          ></Box>
          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "20px",
              color: "#8C8C8C",
              marginTop: "50px",
            }}
          >
            Les engins de terrassement sont indispensables à la construction,
            permettant de creuser des tranchées, de préparer le sol, d'évacuer
            les débris de chantier et de réaliser un nivellement précis des
            fondations en terre.
          </div>
        </Box>

        <Box
          component="img"
          sx={{
            marginLeft: { xs: "0px", sm: "20px" },
            width: { xs: "100%", sm: "50%" },
            borderRadius: "20px",
            maxWidth: "500px",
            display: { xs: "none", sm: "block" },
          }}
          src={terrassement}
          alt="Logo"
        ></Box>
      </Box>

      {/*  NEW ITEMM*/}
      <Box
        style={{ display: "flex" }}
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "center", sm: "unset" },
          marginTop: "50px",
        }}
      >
        <Box
          component="img"
          sx={{
            width: { xs: "100%", sm: "50%" },
            borderRadius: "20px",
            maxWidth: "500px",
            display: { xs: "none", sm: "block" },
          }}
          src={cuisine}
          alt="Logo"
        ></Box>

        <Box
          sx={{
            width: { xs: "unset", sm: "100%" },
            marginLeft: { xs: "0px", sm: "20px" },
            backgroundColor: "#3E5956",
            padding: "16px",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "30px",
              color: "black",
              textAlign: "center",
            }}
          >
            RENOVATION DE CUISINE
          </div>
          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "30px",
              color: "black",
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            Transformez votre cuisine avec de nouvelles fonctionnalités
            exceptionnelles
          </div>

          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "20px",
              color: "#CF908F",
              marginTop: "20px",
            }}
          >
            Envisagez-vous de rénover votre cuisine ?
          </div>
          <Box
            component="img"
            sx={{
              width: { xs: "100%", sm: "50%" },
              borderRadius: "20px",
              maxWidth: "500px",
              height: "300px",
              display: isSmartphone ? "block" : "none",
              marginTop: "16px",
            }}
            src={cuisine}
            alt="Logo"
          ></Box>
          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "20px",
              color: "white",
              marginTop: "20px",
            }}
          >
            <div style={{ marginTop: "20px" }}>
              Demandez dès maintenant votre devis pour une rénovation complète
              de cuisine : agrandissement, réaménagement, optimisation des
              espaces de rangement ou création d'une cuisine ouverte.
            </div>
          </div>

          <div
            style={{
              fontFamily: "Josefin Regular",
              fontSize: "20px",
              color: "white",
              marginTop: "40px",
            }}
          >
            La planification minutieuse de votre nouvelle cuisine est
            essentielle pour vous offrir un confort au quotidien. Nous concevons
            et installons des cuisines sur mesure avec intégration d'appareils
            électroménagers
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default Section4;
