import React from "react";
import { styled } from "@mui/material/styles";

const BlueButton = styled("button")({
  alignSelf: "center",
  position: "relative",
  display: "inline-block",
  cursor: "pointer",
  outline: "none",
  border: 0,
  verticalAlign: "middle",
  textDecoration: "none",
  fontSize: "inherit",
  fontFamily: "Josefin SemiBold",
  "&.learn-more": {
    fontWeight: 600,
    color: "white",
    textTransform: "uppercase",
    padding: "1.25em 2em",
    background: "#0096C7",
    border: "2px solid white",
    borderRadius: "0.75em",
    transformStyle: "preserve-3d",
    transition:
      "transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1)",
    "&::before": {
      position: "absolute",
      content: "''",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "#0081c7",
      borderRadius: "inherit",
      transform: "translate3d(0, 0.75em, -1em)",
      transition:
        "transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1)",
    },
    "&:hover": {
      background: "#0096C7",
      transform: "translate(0, 0.25em)",
      "&::before": {
        boxShadow: "0 0 0 2px #0081c7, 0 0.5em 0 0 #0096C7",
        transform: "translate3d(0, 0.5em, -1em)",
      },
    },
    "&:active": {
      background: "#0096C7",
      transform: "translate(0em, 0.75em)",
      "&::before": {
        boxShadow: "0 0 0 2px #0081c7, 0 0 #0096C7",
        transform: "translate3d(0, 0, -1em)",
      },
    },
  },
});

const BlueButtonComponent = ({ title, sxProps, onClick }) => {
  return (
    <BlueButton onClick={onClick} className="learn-more" style={sxProps}>
      {title}
    </BlueButton>
  );
};

export default BlueButtonComponent;
