import React from "react";
import { Box, Container, Grid } from "@mui/material";
import city from "../assets/footer/fondTest.png";
import logo from "../assets/landing/logo.png";
import "./Footer.css";

const Footer = () => {
  const phoneNumber = "00352661741571"; // Replace with the desired phone number
  const address = "8-10 rue Mathias Hardt, L-1717 Luxembourg";

  const openMaps = () => {
    const encodedAddress = encodeURIComponent(address);
    const mapsUrl = `https://www.google.com/maps/place/${encodedAddress}`;
    window.open(mapsUrl, "_blank");
  };

  const handlePhoneButtonClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };
  return (
    <Box
      id={"footer"}
      sx={{ flexDirection: { xs: "column", sm: "row" } }}
      style={{
        backgroundColor: "#202020",
        backgroundImage: `url(${city})`,
        backgroundSize: "cover",
        padding: "16px",
        minHeight: "600px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{ margin: { xs: "25px", md: "0px" } }}
          style={{
            display: "flex",

            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div
            style={{
              fontFamily: "Josefin Regular",
              color: "white",
              fontSize: "30px",
              marginTop: "30px",
            }}
          >
            8-10 rue Mathias Hardt
          </div>
          <div
            style={{
              fontFamily: "Josefin Regular",
              color: "white",
              fontSize: "30px",
            }}
          >
            L-1717, Luxembourg
          </div>
          <div>
            <button
              onClick={openMaps}
              className="button-19"
              role="button"
              style={{
                marginTop: "20px",
              }}
            >
              Ouvrir sur Maps
            </button>
          </div>
        </Box>
        <Box
          component="img"
          sx={{
            width: { xs: "100%", sm: "90%", md: "50%", lg: "50%", xl: "50%" },
          }}
          src={logo}
          alt="Logo"
        ></Box>
        <Box
          sx={{ margin: { xs: "25px", md: "0px" } }}
          style={{
            display: "flex",

            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div
            style={{
              fontFamily: "Josefin Regular",
              color: "white",
              fontSize: "30px",
              marginTop: "30px",
            }}
          >
            {phoneNumber}
          </div>
          <div>
            <button
              onClick={handlePhoneButtonClick}
              className="button-20"
              role="button"
              style={{ marginTop: "20px" }}
            >
              Nous téléphoner
            </button>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
