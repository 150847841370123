import React from "react";
import { styled } from "@mui/material/styles";

const PinkButton = styled("button")({
  position: "relative",
  display: "inline-block",
  cursor: "pointer",
  outline: "none",
  border: 0,
  verticalAlign: "middle",
  textDecoration: "none",
  fontSize: "inherit",
  fontFamily: "Josefin SemiBold",
  "&.learn-more": {
    fontWeight: 600,
    color: "#382b22",
    textTransform: "uppercase",
    padding: "1.25em 2em",
    background: "#ffebaa",
    border: "2px solid #c28647",
    borderRadius: "0.75em",
    transformStyle: "preserve-3d",
    transition:
      "transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1)",
    "&::before": {
      position: "absolute",
      content: "''",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "#f0d068",
      borderRadius: "inherit",
      transform: "translate3d(0, 0.75em, -1em)",
      transition:
        "transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1)",
    },
    "&:hover": {
      background: "#ffebaa",
      transform: "translate(0, 0.25em)",
      "&::before": {
        boxShadow: "0 0 0 2px #b18597, 0 0.5em 0 0 #ffebaa",
        transform: "translate3d(0, 0.5em, -1em)",
      },
    },
    "&:active": {
      background: "#ffebaa",
      transform: "translate(0em, 0.75em)",
      "&::before": {
        boxShadow: "0 0 0 2px #b18597, 0 0 #ffebaa",
        transform: "translate3d(0, 0, -1em)",
      },
    },
  },
});

const PinkButtonComponent = ({ title, sxProps, onClick, type, disabled }) => {
  return (
    <PinkButton
      type={type}
      disabled={disabled}
      onClick={onClick}
      className="learn-more"
      style={sxProps}
    >
      {title}
    </PinkButton>
  );
};

export default PinkButtonComponent;
