import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper/modules";
import slider1 from "../assets/section3/buderus1.jpeg";
import slider2 from "../assets/section3/buderus.jpeg";
import slider3 from "../assets/section3/buderus3.jpeg";
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const StyledSlider = styled(Box)({
  backgroundSize: "cover",
  backgroundPosition: "center center",
  width: "100%",
  height: "700px",
  position: "relative",
  borderRadius: "20px",
});
const Section3 = () => {
  return (
    <div
      style={{
        backgroundColor: "#FBF9F7",
        padding: "16px",
      }}
    >
      <div
        style={{
          fontFamily: "Josefin Bold",
          fontSize: "50px",
          textAlign: "center",
          marginTop: "50px",
          marginBottom: "50px",
          color: "#202020",
        }}
      >
        Nos réalisations
      </div>
      <Swiper
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        // install Swiper modules
        navigation
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        slidesPerView={1}
      >
        <SwiperSlide>
          <StyledSlider
            sx={{ backgroundImage: `url(${slider1})` }}
          ></StyledSlider>
        </SwiperSlide>
        <SwiperSlide>
          <StyledSlider
            sx={{ backgroundImage: `url(${slider2})` }}
          ></StyledSlider>
        </SwiperSlide>
        <SwiperSlide>
          <StyledSlider
            sx={{ backgroundImage: `url(${slider3})` }}
          ></StyledSlider>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Section3;
