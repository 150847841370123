import React, { useEffect, useRef, useState } from "react";
import "./NavigationMenu.css";
import MainIcon from "../../assets/landing/whatsapp.svg";
import Icon1 from "../../assets/landing/call.png";
import Icon2 from "../../assets/landing/mail.png";
import emergencyIcon from "../../assets/landing/urgence.png";
import crossIcon from "../../assets/landing/croix.svg";

function NavigationMenu() {
  const [expanded, setExpanded] = useState(false);

  // Référence à la div du composant pour vérifier les clics en dehors
  const componentRef = useRef(null);

  useEffect(() => {
    // Ajouter un écouteur d'événement pour les clics au niveau du document
    document.addEventListener("click", handleClickOutside);

    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []); // [] signifie que cela s'exécute seulement lors du montage et du démontage

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      // Clic en dehors du composant, fermer le menu
      const fab = document.querySelector(".fab");

      setExpanded(false);
      fab.classList.remove("expanded");
    }
  };

  useEffect(() => {
    const imgElement = document.querySelector(".fab img.icon");

    if (expanded) {
      imgElement.src = crossIcon;
    } else {
      imgElement.src = emergencyIcon;
    }
  }, []);
  const expandFab = () => {
    setExpanded(!expanded);
    const imgElement = document.querySelector(".fab img.icon");
    const fab = document.querySelector(".fab");

    if (expanded) {
      imgElement.src = crossIcon;
    } else {
      imgElement.src = emergencyIcon;
    }
    fab.classList.toggle("expanded");
  };
  console.log("🇱🇺".toString());
  const openWhatsapp = () => {
    const message =
      "LUX-HOME 24\n" +
      "\n" +
      "🇫🇷 Bienvenue \n" +
      "\n" +
      "pour un traitement efficace de votre dossier, prière de:\n" +
      "\n" +
      "1️⃣ Enregistrer notre Numero\n" +
      "\n" +
      "2️⃣ Envoyez 1 message avec vos\n" +
      "Nom, Prénom\n" +
      "Adresse L-xxxx\n" +
      "Nr. mobile\n" +
      "E-mail (facture)\n" +
      "\n" +
      "3️⃣ Décrivez votre problème \n" +
      "\n" +
      "LUX-HOME 24\n" +
      "\n" +
      " 🇬🇧 Welcome\n" +
      "\n" +
      " for efficient processing of your file, please:\n" +
      "\n" +
      " 1️⃣ Save our number\n" +
      "\n" +
      " 2️⃣ Send 1 message with your\n" +
      " Last name First Name\n" +
      " Address L-xxxx\n" +
      " mobile number\n" +
      " Email (invoice)\n" +
      "\n" +
      "3️⃣Describe your problem\n" +
      "\n" +
      "LUX-HOME 24\n" +
      "\n" +
      " 🇱🇺 Wëllkomm\n" +
      "\n" +
      " fir effizient Veraarbechtung vun Ärem Fichier, w.e.g.:\n" +
      "\n" +
      " 1️⃣ Späichert eis Nummer\n" +
      "\n" +
      " 2️⃣ Schéckt 1 Message mat Ärem\n" +
      " Virnumm Virnumm\n" +
      " Adress L-xxxx\n" +
      " Handysnummer\n" +
      " E-Mail (Rechnung)\n" +
      "\n" +
      "3️⃣Beschreift Äre Problem";

    const encodedMessage = encodeURI(message);
    window.open(`https://wa.me/+352661741571?text=${encodedMessage}`, "_blank");
  };

  const phoneNumber = "00352661741571"; // Replace with the desired phone number

  const handlePhoneButtonClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const goToForm = () => {
    const targetElement = document.getElementById("targetDiv");
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <div className="nav-container" ref={componentRef}>
      <div className="fab">
        <img
          onClick={expandFab}
          style={{ cursor: "pointer", width: "48px", height: "48px" }}
          className={"icon"}
          src={expanded ? crossIcon : emergencyIcon}
        />

        <div className="fab_actions">
          <img
            style={{ cursor: "pointer" }}
            onClick={openWhatsapp}
            className={"icon"}
            src={MainIcon}
          />

          <img
            style={{ cursor: "pointer" }}
            onClick={handlePhoneButtonClick}
            className={"icon"}
            src={Icon1}
          />

          <img
            style={{ cursor: "pointer" }}
            onClick={goToForm}
            className={"icon"}
            src={Icon2}
          />
        </div>
      </div>
    </div>
  );
}

export default NavigationMenu;
